$('document').ready(function(){

    $('.btn-info-ing').click(function () {
        dataLayerPushFilter($(this));
    });

    $('.btn-info-prep').click(function () {
        dataLayerPushFilter($(this));
    });

    $('.see-recipe-details').click(function () {
        dataLayerPushDetail($(this));
    });

});

function dataLayerPushFilter(eventAction) {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event' : 'gaEvent',
        'eventCategory': 'recipes',
        'eventAction': 'Instructions tab | '+$(eventAction).attr('name'),
        'eventLabel': 'recipes/'+cat+url
    });
}

function dataLayerPushDetail(eventAction) {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event' : 'gaEvent',
        'eventCategory': 'recipes',
        'eventAction': 'view detail',
        'eventLabel': 'recipes/'+$(eventAction).attr('name')
    });
}

var _push_custom_event_to_GA4 = function (){
    if (arguments.length < 1){
        console.error('Please specify at least the first parameter for eventName.');
        return;
    } else if (arguments.length % 2 === 0) {
        console.error('The number of arguments must be odd. Arguments should be as followed: eventName, (param1, value1, param2, value2...). Keys/values are optional\'');
        return;
    }

    let pushContent = {
        'event': 'ga4Event',
        'eventName': arguments[0]
    }
    if (arguments.length > 1){
        for (let i = 1; i < arguments.length; i += 2) {
            pushContent[arguments[i]] =  arguments[i + 1];
        }
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(pushContent);
    console.log('GA4 Event Pushed:', pushContent);
}

window['push_search_result_metrics'] = function (keyword, totalResults, isStoreSearch){
    let searchCategoryGA4 = isStoreSearch ? 'store' : 'global';
    let searchCaseGA4 = totalResults > 0 ?'with results' : 'without results';

    _push_custom_event_to_GA4 (
        'search',
        'search_term', keyword,
        'search_case', searchCaseGA4,
        'search_Category', searchCategoryGA4
    );
};

window['push_contest_participation_metrics'] = function (linkText, linkUrl){
    _push_custom_event_to_GA4 (
        'contest_participation_intent',
        'link_text', linkText,
        'link_url', linkUrl
    );
};

document.addEventListener('DOMContentLoaded', function() {
    // Main search bar
    let keyword = document.querySelector('.form-search input[name="q"]').value;
    let results = document.querySelectorAll('.list-group-item');
    let totalResults = results.length;

    let infoSent = false;

    if (totalResults > 0) {
        push_search_result_metrics(keyword, totalResults, false);
    } else {
        let observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    totalResults = document.querySelectorAll('.list-group-item').length;
                    if (totalResults > 0) {
                        push_search_result_metrics(keyword, totalResults, false);
                        observer.disconnect();
                    } else {
                        let noResultsMessage = document.querySelector('h2.h4');
                        if (noResultsMessage && noResultsMessage.textContent === 'Aucun résultat trouvé.' && !infoSent) {
                            // Si le message "Aucun résultat trouvé" est présent, envoyer les informations à GA4 indiquant qu'il n'y a pas de résultats et arrêter l'observation
                            push_search_result_metrics(keyword, totalResults, false);
                            infoSent = true;
                            observer.disconnect();
                        }
                    }
                }
            });
        });

        // Démarrer l'observation
        observer.observe(document.body, {childList: true, subtree: true});
    }
});

document.addEventListener('DOMContentLoaded', function() {
    // Contest participation button
    document.querySelectorAll('.contest-right .btn').forEach(button => {
        button.addEventListener('click', function (event) {
            let contestTitle = this.closest('.contest-right').querySelector('h2').textContent;
            let contestUrl = this.getAttribute('href');
            push_contest_participation_metrics(contestTitle, contestUrl);
        });
    });
});

